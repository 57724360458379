import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isLogin)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_router_link, { to: "/project" }, {
              default: _withCtx(() => [
                _createTextVNode("工事計画")
              ]),
              _: 1
            }),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, { to: "/scheduler" }, {
              default: _withCtx(() => [
                _createTextVNode("計画一覧表")
              ]),
              _: 1
            }),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, { to: "/qrviewer" }, {
              default: _withCtx(() => [
                _createTextVNode("QRコード読取")
              ]),
              _: 1
            }),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, { to: "/memberlist" }, {
              default: _withCtx(() => [
                _createTextVNode("スタッフ一覧")
              ]),
              _: 1
            }),
            _createTextVNode(" | "),
            _createVNode(_component_router_link, { to: "/excel" }, {
              default: _withCtx(() => [
                _createTextVNode("エクセル出力")
              ]),
              _: 1
            }),
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.LogOutView && _ctx.LogOutView(...args))),
              style: {"float":"right"}
            }, "ログアウト")
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_router_view),
    _createVNode(_component_a_modal, {
      open: _ctx.visible,
      "onUpdate:open": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
      onOk: _ctx.LogOut
    }, {
      default: _withCtx(() => [
        _createTextVNode(" ログアウトしますか？ ")
      ]),
      _: 1
    }, 8, ["open", "onOk"])
  ], 64))
}