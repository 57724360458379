import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project.vue')
  },
  {
    path: '/scheduler',
    name: 'scheduler',
    component: () => import('../views/Scheduler.vue')
  },
  {
    path: '/qrviewer',
    name: 'qrviewer',
    component: () => import('../views/QRViewer.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/login/:staffcode',
    name: 'logincode',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/memberlist',
    name: 'memberlist',
    component: () => import('../views/MemberList.vue')
  },
  {
    path: '/excel',
    name: 'excel',
    component: () => import('../views/Output.vue')
  },
  {
    path: '/qrreader',
    name: 'qrreader',
    component: () => import('../views/QRReader.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
