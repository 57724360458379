
import { defineComponent, onMounted, ref } from 'vue';
import { useCookies } from 'vue3-cookies';
import router from './router';

export default defineComponent({
  name: 'Home',
  components: {
    
  },

  watch: {
    '$route': function (to, from) {
      if (to.path !== from.path) {
        this.setLogin()
      }
    }
  },

  setup(){
    const cookies  = useCookies();

    const visible = ref(false)
    const isLogin = ref(true)

    onMounted(()=>{
      setLogin()
    })

    const LogOutView = () => {
      visible.value = true
    }

    const LogOut = () => {
      cookies.cookies.remove('token');
      visible.value = false
      isLogin.value = false
      router.push("/login")
    }

    const setLogin = () => {
      var token = cookies.cookies.get('token');
      isLogin.value = token != null
    }

    return {
      visible,
      LogOutView,
      LogOut,
      isLogin,
      setLogin,
    }
  }
});
